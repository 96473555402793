<template>
  <BasicModal
    :show-cancel-button="false"
    :show-action-button="false"
  >

    <template v-if="isLoading">
      <div class="flex items-center justify-center">
        <LoadingIcon />
      </div>
    </template>

    <template v-else>

      <div class="icons">
        <img :src="getPatchEventRowIconUrl(patchEvent.patch)" />
        <ArrowIcon class="arrow-icon" />
        <img :src="patchEvent.patch.action.iconUrl" />
      </div>

      <h5 class="break-words">{{ patchEvent.patch.name }}</h5>

      <PatchEventDetails
        :show="true"
        :patchEvent="patchEvent"
        location="patch-event-modal"
      />

    </template>

  </BasicModal>
</template>

<script>

  import { mapGetters } from 'vuex'

  import BasicModal from '@/components/modals/_BasicModal.vue'
  import PatchEventDetails from '@/components/tables/PatchEventDetails.vue'

  import ArrowIcon from '@/assets/icons/arrow.svg'
  import LoadingIcon from '@/components/utils/LoadingIcon.vue'

  export default {
    components: {
      ArrowIcon,
      BasicModal,
      LoadingIcon,
      PatchEventDetails,
    },
    props: {
      patchEventId: {
        type: String,
        required: true,
      },
    },
    computed: {
      ...mapGetters('app', ['getPatchEventRowIconUrl']),
    },
    data() {
      return {
        isLoading: false,
        patchEvent: null,
      }
    },
    created() {

      this.isLoading = true

      return this.$store.state.api.dispatch.get(`/patch-events/${this.patchEventId}`)
        .then((response) => {
          this.patchEvent = response.data
          if (
            this.patchEvent.patch.trigger.slug === 'smart-contract-activity'
            || this.patchEvent.patch.trigger.slug === 'nft-collection-items-transferred'
          ) {
            this.$store.commit('modals/SET_CURRENT_MODAL_MODE', 'wide')
          }
        })
        .catch(() => {
          this.$store.dispatch('modals/CLOSE_MODAL')
          this.$store.dispatch('toast/CREATE_TOAST', {
            text: `Could not load Patch event with id ${this.patchEventId}. Please try again later.`,
            type: 'error',
          })
        })
        .finally(() => {
          this.isLoading = false
        })

    },
  }

</script>

<style lang="stylus" scoped>

  .icons
    @apply mb-4
    @apply space-x-2

    @apply flex
    @apply items-center

    svg
    img
      @apply w-6
      @apply h-6

    .arrow-icon
      @apply w-4
      @apply h-4

  h5
    @apply pb-4
    @apply mb-4
    @apply border-b
    @apply border-gray-400

</style>
